body {
  margin: 0;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /*
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  background-color: #ffffff;
  color: #29353d;
}

.markerLabel{
  z-index: 1000 !important;
  /*margin-top: 3px !important;
  margin-left: 3px !important;*/
  font-size: 12px;
  color: #fff;
  padding: 2px 4px 2px 4px;
  text-shadow: 1px 1px 1px #000, -1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000;
  background: rgba(0,0,0,0.0);
  border-radius: 3px;
}

.markerLabel-1{
  font-size: 18px;
  color: #eee;
  font-weight: bold;
  background: rgba(0,0,0,0.3);
}

.labelValue{
  white-space: nowrap;
}

.labelUnit{
  font-size: 10px;
  padding-left: 2px;
}

div.rdtPicker{
  /*right: 0px;*/ /* Align picker to the right side of the input */
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.5) !important;
  z-index: 1000001 !important;
}

.btn.btn-dark{
  background-image: linear-gradient(#6b6161, #403737);
  color: #ccc;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  /*box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);*/
}

.btn.btn-dark.active{
  text-shadow: 0px 0px 4px #ff5f0d;
  color: #ffe5d7 !important;
  /*text-shadow: -2px 0 #ff5f0d, 0 2px #ff5f0d, 2px 0 #ff5f0d, 0 -2px #ff5f0d;*/
}

.btn.btn-dark:hover{
  color: #fff !important;
}

.btn.btn-dark:disabled{
  background-color: #777;
  background-image: none;
  color: #ccc;
  text-shadow: none;
  cursor: auto;
}

.btn.btn-dark:disabled:hover{
  color: #ccc !important;
}

@media only screen and (max-width: 512px) {
  body {
    font-size: 12px;
  }
}
