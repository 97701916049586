@font-face {
  font-family: 'Red Hat Text';
  font-weight: 400;
  font-style: normal;
  src: local('Red Hat Text Regular'), url(../fonts/RedHatText-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Red Hat Text';
  font-weight: 500;
  font-style: normal;
  src: local('Red Hat Text Medium'), url(../fonts/RedHatText-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Red Hat Text';
  font-weight: 700;
  font-style: normal;
  src: local('Red Hat Text Bold'), url(../fonts/RedHatText-Bold.ttf) format('truetype');
}