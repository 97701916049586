.container{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pickContainer{
  white-space: nowrap;
  display: inline-block;
}

.pickTime{
  font-size: 0.9em;
  color: #444;
  padding-right: 12px;
  min-width: 190px;
  display: inline-block;
}

@media(max-width: 576px) {
  .pickTime{
    min-width: 0;
  }
}

.pickLabel{
  font-weight: bold;
  font-size: 0.7em;
  padding-right: 6px;
}

.pickValue{
  font-weight: bold;
  font-size: 0.9em;
}

.pickUnit{
  font-weight: bold;
  font-size: 0.8em;
  padding-left: 3px;
}

.pickRemove{
  float: right;
  padding: 0 !important;
  margin: 0;
}

@media print{
  .pickRemove{
    display: none !important;
  }
}

.pickRemove svg{
  color: #f30;
}

.pickSeparator{
  border-left: solid 1px #aaa;
  margin: 0px 6px 0px 6px;
}

.tooltip{
  background-color: rgba(255, 255, 255, 1);
  padding: 8px;
  border: solid 1px #aaa;
  font-size: 0.9em;
}

.tooltipTitle{
  display: block;
  font-weight: bold;
  border-bottom: solid 1px #ddd;
  margin-bottom: 4px;
  font-size: 0.9em;
  color: #555;
}

.tooltipItem td{
  line-height: 1.4em;
  white-space: nowrap;
}

.tooltipItemLabel{
  padding-right: 6px;
}

.tooltipItemValue{
}

.tooltipItemUnit{
  font-size: 0.8em;
  padding-left: 3px;
}

.attributeSelector{
  font-size: 0.8em;
}

.attributeSelectorItem{
 white-space: nowrap;
}

.attributeSelectorCheckbox{
  display: inline-block !important;
  margin-left: 6px;
}