.modal-content{
  /*box-shadow: 0 0px 32px 0 rgba(255, 95, 13, 0.4);*/
  box-shadow: 0 0px 32px 0 rgba(0, 0, 0, 0.5);
  background: transparent !important;
}

.modal-body{
  background-color: #fff;
}

.modal-header{
  background-color: #000;
  /*background-image: linear-gradient(to right, #400 , #800);*/
  background-image: linear-gradient(to left, #6b6161, #403737);
  box-shadow: inset 10px -20px 20px 0 rgba(0, 0, 0, 0.2), inset -5px 10px 10px 0 rgba(200, 200, 200, 0.2);
  color: #fff;
  /*text-shadow: 0px 0px 8px #ff5f0d;*/
  text-shadow: 0px 0px 8px #000;
  max-height: 70px;
  font-weight: bold;
  font-size: 1.2em;
  /*border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;*/
  /*border: solid 1px #300;*/
}

.modal-footer{
  background-color: #eee;
}